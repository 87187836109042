.tag-wrapper {
  @apply flex mb-10 justify-around;

  @media (max-width: 600px) {
    @apply flex-col mb-0;
  }
}

.tags-container {
  @media(max-width: 600px) {
    @apply flex flex-col items-end;
  }
}

.individual-tag {
  @apply flex flex-col;

  @media(max-width: 600px) {
    @apply mt-4;
  }
}

.tag-button {
  @media (max-width: 600px) {
    @apply mt-7 mb-5;
  }
}
