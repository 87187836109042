.es-title {
  @media(min-width: 600px) {
    font-size: 33px;
  }

  @media(max-width: 600px) {
    font-size: 25px;
  }
}

.es-mailBox {
  @media(min-width: 600px) {
    bottom: 95px;
    max-height: 63%;
    right: 13.6%;
  }

  @media(max-width: 600px) {
    bottom: 50px;
    max-height: 50%;
    right: 7.6%;
  }

  max-width: 216px;
  position: absolute;
}

.es-floor {
  @media(min-width: 600px) {
    height: 180px;
  }

  @media(max-width: 600px) {
    bottom: 20px;
    position: absolute;
    right: -3px;
  }
}
