.responseForm {
  &-wrapper {
    @apply bg-white px-2 pt-2;
    border: 1px solid rgba(138, 143, 177, 0.49);
    position: relative;
  }
}

.responseInput {
  min-height: 5em;

  &__input {
    margin: 0;
    overflow: auto;
    width: 100%;
  }

  &__suggestions {
    &__list {
      background-color: white;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: .3rem;
      box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
      font-size: 14px;
    }

    &__item {
      padding: 5px 15px;

      &--focused {
        @apply text-vivid-blue;
      }
    }
  }
}

.questions-batch-response {
  @apply bg-white rounded-t-xl rounded-b-sm absolute w-full pin-b pin-l;

  transform: translateY(100%);
  transition: transform ease .4s;

  .batch-response & {
    box-shadow: 0 -1px 16px 0 rgba(21, 33, 91, 0.34);
    transform: translateY(81%);
  }

  .responseForm-actions {
    &__cancel {
      @apply py-2 px-4 inline-block opacity-50;
      color: #151e47;
      font-family: Arboria-Medium;
    }
  }
}

.questions-list-wrapper {
  .batch-response & {
    padding-bottom: 50px;
  }
}

.responseForm-presets {
  .btn {
    @apply bg-transparent text-vivid-blue font-semibold px-4 mr-2 border border-vivid-blue rounded-xs;

    @media(max-width: 600px) {
      @apply py-1;
    }

    @media(min-width: 600px) {
      @apply py-2;
    }

    &:hover {
      @media(min-width: 600px) {
        @apply bg-vivid-blue text-white border-transparent;
      }
    }

    &--secondary {
      @apply text-orange border-orange;

      @media(min-width: 600px) {
        &:hover {
          @apply bg-orange;
        }
      }
    }
  }
}

.responseForm-actions {
  @media (min-width: 600px) {
    @apply my-5;
  }
  @media(max-width: 600px) {
    @apply justify-end;
  }
  align-items: center;
  display: flex;

  .btn {
    @apply bg-vivid-blue text-white mr-2 border border-vivid-blue;
    border-radius: 12.5px;
    font-family: Arboria-Medium;
    padding: 5px 13px;
  }

  &__cancel {
    @media(min-width: 600px) {
      @apply flex-1;
    }
    @apply px-4 py-2 inline-block;
    color: #151e47;
  }

  &__delete {
    @apply text-danger;
    flex: 0 1 auto;
  }

  a {
    cursor: pointer;
  }
}


.questions-toolbox {
  @apply pb-12;
  color: #192354;
  display: flex;
  font-size: 13px;
}

.userquestion {
  &-wrapper {
    @apply mb-3;
    display: flex;

    .question-checkbox-wrapper {
      @apply px-1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 17px 17px 17px 0;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  77% {
    height: auto;
    opacity: 0;
    overflow: hidden;
  }

  100% {
    height: 0;
    opacity: 0;
  }
}

.fade {
  animation-duration: .4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: fade;
}

.user-question {
  @apply bg-grey flex-1 rounded border border-solid border-grey;

  @media screen and (min-width: 767px) {
    &:hover {
      @apply border-vivid-blue;
    }
  }

  .question-reply {
    @apply text-vivid-blue ml-5;
    display: none;
    font-weight: bold;
    line-height: 1.5em;
  }

  &:not(.open) {
    &:hover {
      .question-date {
        display: none;
      }

      .question-reply {
        display: inline;
      }
    }
  }

  &.checked {
    @apply border-vivid-blue;
  }
}

.user-question__userinfo {
  @apply mb-4 pt-2;
  font-size: 12px;

  @media (max-width: 640px) {
    @apply mb-4 pt-2 flex flex-col;
    font-size: 12px;
  }
}

.user-question__username {
  color: #192354;
  opacity: .6;
}

.user-question__question {
  @media (min-width: 640px) {
    @apply flex-1 cursor-pointer flex items-center justify-between;
  }

  @apply cursor-pointer;

  .open > & {
    padding-bottom: 0;
  }
}

.user-question__question__dialog {
  @apply flex flex-row flex-1 items-center py-4;

  &.open {
    align-items: stretch;
  }
}

.user-question-reply__question {
  @apply flex-1 pb-4 cursor-pointer flex items-start justify-between;
}

.question-text {
  color: black;
  font-weight: 500;
  margin-bottom: 3px;
}

.question-date {
  @media (max-width: 640px) {
    @apply ml-7;
  }

  @apply ml-5;
  color: #192354;
  line-height: 1.5em;
}

.predefined-answers {
  display: flex;
  flex: 1;
  flex-direction: column;

  &-categories {
    @apply text-dark-blue-grey;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      text-align: center;
      width: 40%;

      &:last-child {
        border-bottom-style: none;
      }
    }
  }
}

.predefined-answers-section {
  @apply p-6 bg-white;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  transition: transform ease .4s;
  width: 100%;
}

.predefined-answers__description {
  @apply font-sans text-sm text-dark-slate-blue p-3 leading-normal;
}

.predefined-answers__body {
  flex: 1 1 0;
  overflow: auto;
}

.predefined-answers-item {
  @apply py-2;

  &--editable {
    textarea:focus,
    input:focus {
      border-style: dashed;
    }
  }

  &__label {
    @apply py-2;

    color: #ee7c16;
    display: block;
    font-family: Arboria;
    font-size: 13px;
    font-weight: 600;
  }

  &__input {
    background-color: white;
    border: solid 1px rgba(138, 143, 177, 0.49);
    border-radius: 5px;
    color: #192354;
    display: block;
    font-family: Arboria;
    font-size: 13px;
    line-height: 1.15;
    padding: 1em;
    width: 100%;

    &--empty {
      border-style: dashed;
    }
  }
}

.predefined-answers-item__label-edit {
  align-items: center;
  display: flex;

  .edit {
    @apply text-vivid-blue;
    font-size: small;
    padding-left: 0.5em;
  }

  .delete {
    @apply text-danger mx-4 cursor-pointer;
    font-size: small;
  }
}

.predefined-answers-item__label-input {
  border: solid 1px #d3d3d3;
  border-radius: 5px;
  flex: 1;
  padding: 4px 4px 4px 20px;
}

.predefined-answers-new-item {
  .btn {
    @apply border-vivid-blue bg-white text-vivid-blue w-full m-0;
    border-style: dashed;
    font-weight: normal;
    text-align: left;
  }
}

.predefined-answers__footer {
  .btn,
  .btn-cancel {
    @apply w-full py-3;
  }
}

.card-enter-active,
.card-enter-done {
  transform: translateX(0);
}

.selected {
  border-style: dashed;
}

.dot {
  @apply absolute bg-red-light flex h-3 items-center justify-center mr-2 pin-r rounded-full text-white w-3;
  top: 2px;
}

.margin_mobile {
  @media (max-width: 640px) {
    @apply mb-2;
  }
}

.delete_mobile {
  @media (max-width: 600px) {
    @apply py-2 text-center mt-1;
  }
}

.question-list {
  @media(max-width: 600px) {
    @apply ml-4;
  }

  @apply text-blue-darkest text-2xl mb-2;
}

.question-list-text {
  @media(max-width: 600px) {
    @apply ml-4;
  }
}

.stock-and-price {
  @media(max-width: 600px) {
    @apply hidden;
  }

  @apply flex;
}

.hidden-in-mobile {
  @media(max-width: 600px) {
    @apply hidden;
  }
}
