.responseForm-buttons {
  @apply flex mt-3 justify-between;

  @media(min-width: 600px) {
    @apply items-center;
  }

  @media(max-width: 600px) {
    @apply flex-col mb-2;
  }
}