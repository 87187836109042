.erp-selection-right-side {
  @apply w-full px-4 py-10 flex items-center overflow-x-hidden pl-16;

  @media(max-width: 600px) {
    @apply px-2 py-10;
    border-top: solid 1px lightgrey;
  }
}

.erp-selection-left-side {
  @apply p-4 pb-10 flex-col justify-center;

  @media(min-width: 1024px) {
    @apply pl-6 pr-4 py-7 flex w-1/2 border-r-2;
  }
}

.erp-selection-container {
  @apply flex-1 flex;

  @media(max-width: 600px) {
    @apply flex-col;
  }
}

