.onboarding {
  a {
    @apply font-semibold text-vivid-blue cursor-pointer;
  }

  .description {
    @apply font-arboria;
  }

  .field {
    @apply border-bluey-grey;
  }

  .error {
    .field {
      @apply border-red;
    }
  }

  .success {
    .field {
      @apply border-green;
    }
  }
}

.onboarding__main-card {
  @media (min-width: 1200px) {
    width: 1111px;
  }
}
