@keyframes change-content {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.switch {
  clip-path: circle(0%);
  opacity: 0;
  position: absolute;

  + .switch-control {
    @apply border-light-periwinkle;
    border-radius: 30px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: inline-block;
    height: 14px;
    margin: 0 4px;
    position: relative;
    text-align: center;
    transition: border .3s ease-in-out;
    width: 28px;

    &::before,
    &::after {
      display: inline-block;
      position: absolute;
    }

    &::before {
      @apply bg-bluey-grey;
      border-radius: 50%;
      content: '';
      height: 8px;
      left: 2px;
      top: 2px;
      transition: all .3s ease-in-out;
      width: 8px;
    }

    &::after {
      bottom: -2.4px;
      color: #607d8b;
      display: inline-block;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:checked {
    + .switch-control::before {
      left: 14px;
      @apply bg-vivid-blue;
    }

    + .switch-control {
      @apply border-vivid-blue;
    }

    + .switch-control::after {
      animation: change-content .3s forwards linear;
    }
  }
}
