#crm {
  @media(max-width: 1030px) {
    padding: 15px 0 60px;
  }

  @media(min-width: 1030px) {
    padding: 30px 0 60px;
  }

  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.crm-title {
  @apply flex flex-col;

  @media(min-width: 1030px) {
    @apply mb-4;
  }

  width: 95%;
}
