.dropdown {
  cursor: pointer;
  display: inline-block;
  outline: 0;
  position: relative;
  text-align: left;
  -webkit-transition: width .1s ease, -webkit-box-shadow .1s ease;
  transition: width .1s ease, -webkit-box-shadow .1s ease;
  transition: box-shadow .1s ease, width .1s ease;
  transition: box-shadow .1s ease, width .1s ease;

  &.inline {
    color: inherit;
    display: inline;
  }

  > .text {
    display: inline-block;
    font-weight: 700;
    transition: none;
  }

  .dropdown-list {
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    // display: none;
    border-radius: .3rem;
    -webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
    cursor: auto;
    font-size: 0.8em;
    margin: 0;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    outline: 0;
    padding: 0 0;
    position: absolute;
    text-align: left;
    text-shadow: none;
    top: 120%;
    transition: opacity .1s ease;
    will-change: transform, opacity;
    z-index: 11;

    &-inverted {
      bottom: 120%;
      top: auto;
    }

    > * {
      white-space: nowrap;
    }

    &__item {
      box-shadow: none;
      cursor: pointer;
      display: block;
      font-size: 0.8rem;
      font-weight: 400;
      height: auto;
      line-height: 1em;
      padding: 0.6rem 1.0rem !important;
      position: relative;
      text-align: left;
      text-transform: none;

      &:first-child {
        border-top-width: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
