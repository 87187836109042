.checkbox {
  @apply border border-solid border-bluey-grey rounded-sm;
  align-content: center;
  display: inline-grid;
  height: 14px;
  justify-content: center;
  transition: border-color .2s;
  width: 14px;

  &--checked {
    @apply border-vivid-blue;
  }
}

.checkbox__indicator {
  @apply rounded-full;
  display: block;
  height: 6px;
  transition: background-color .3s;
  width: 6px;

  .checkbox--checked > & {
    @apply bg-vivid-blue;
  }
}

.btn {
  @apply bg-vivid-blue text-white font-semibold py-2 px-4 mr-2 border border-vivid-blue rounded-xs;

  &:focus {
    @apply outline-none;
  }

  &--inverted {
    @apply bg-transparent text-vivid-blue;

    &:hover {
      @apply bg-vivid-blue text-white border-transparent;
    }

    &--cancel {
      @apply mr-0;
    }
  }
}
