.greetings-container {
  @apply flex justify-around mb-24;

  @media (max-width: 600px) {
    @apply flex-col mb-12;
  }
}

.greetings-buttons {
  @media(max-width: 600px) {
    @apply flex flex-row-reverse items-center;
  }

  @media (min-width: 600px) {
    @apply flex-col;
  }
}

.jump-step {
  @media (min-width: 600px) {
    @apply mt-4;
  }

  @media (max-width: 600px) {
    @apply mr-5;
  }
}