.header {
  @apply bg-blue-darkest flex items-center justify-between py-4 px-9 h-15;

  .section-link {
    @apply text-white no-underline flex items-center px-6;
    outline: none;

    &:hover,
    &--active {
      @apply text-light-greenish-blue;
    }
  }
}
