.MuiTableRow-root {
  .MuiTableCell-root {
    @apply border border-solid border-blue-lighter font-arboria;

    &:last-child {
      @apply border-r-0;
    }
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #2b48f0 !important;
}

tr[level="1"] > td:first-of-type {
  border-bottom: 1px solid white;
}

tr[level="0"] {
  border-top: 1px double lightgrey;
}
