@import "../../node_modules/react-toastify/dist/ReactToastify.css";

.Toastify {
  &__toast-container {
    padding: 0 !important;

    &--top-center {
      top: 0 !important;
    }
  }

  &__toast {
    @apply rounded-b-xl h-24 w-96;

    .toastBody {
      @apply text-sm font-medium text-white;
    }

    .spanRetry {
      @apply absolute pin-b pin-r text-white underline p-0 pr-5 pb-3;
    }

    .spanMessage {
      @apply text-white flex justify-center items-center pr-12;
    }

    .success-spanMessage {
      @apply text-white flex justify-center items-center;
      font-size: 18px;
    }

    .spanSvg {
      @apply pr-5;
    }

    &.error-toast {
      background-color: #ff3457;
    }

    &.success-toast {
      @apply bg-green relative;
    }
  }
}
