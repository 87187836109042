@import '../../node_modules/animate.css/animate.css';

.swal2-container {
  z-index: 2000 !important;
}

@tailwind preflight;
* {
  @apply font-arboria;
}

body {
  @apply text-base text-dark-blue-grey;
}

a {
  @apply no-underline;

  &:visited {
    color: inherit;
  }
}

@tailwind components;
@tailwind utilities;

.rotate- {
  &1\/4 {
    transform: rotate(90deg);
  }

  &2\/4 {
    transform: rotate(180deg);
  }

  &3\/4 {
    transform: rotate(270deg);
  }
}

.word-break {
  word-break: break-word;
}

textarea,
select,
input,
button,
button:focus {
  outline: none;
}

.card {
  @apply p-6 bg-white;
  box-shadow: 0 2px 2px 0 rgba(38, 40, 66, 0.08);
}

html {
  scroll-behavior: smooth;
}

.section {
  @media(min-width: 1030px) {
    margin-top: 60px;
  }

  @media(max-width: 1030px) {
    margin-top: 0;
  }
}
